import {findAll, on} from "@elements/dom-utils"

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import "bootstrap/js/dist/collapse";

import 'bootstrap/js/dist/modal';

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as shariff from './shariff';
shariff.init();

import * as formValidation from './form-validation';
formValidation.init();

import * as playVideo from './play-video';
playVideo.init();

import * as affixNav from './affix-nav';
affixNav.init();

import * as megaNav from './mega-nav';
megaNav.init();

import * as capacityIndicator from './capacity-indicator';
capacityIndicator.init();

import * as datepicker from '@elements/datepicker';
datepicker.init();

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as rangeDatepicker from '@elements/datepicker-range';
rangeDatepicker.init();

import { init } from "@elements/cookie-permissions/bootstrap-5";
if (!_config.admin && !_config.editmode) {
    init();
}

import { showCookiesSettings } from "@elements/cookie-permissions";
findAll('.js-show-cookie-modal').map(on('click', showCookiesSettings));