"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, find, findAllIn, findIn} from "@elements/dom-utils";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    let mainNav = find('.js-mega-nav');
    if(mainNav) {
        onFind('.js-mega-nav', function (item) {
            let $nav = item,
                $toggle = find('.js-mega-nav__toggle', $nav);

            on('click', function () {
                if (hasClass('is-open', $nav)) {
                    removeClass('is-open', $nav);
                    removeClass('is-open', $toggle);
                    deactivateScrollLock();
                } else {
                    addClass('is-open', $nav);
                    addClass('is-open', $toggle);
                    activateScrollLock();
                }
            }, $toggle);
        });

        onFind('.js-mega-nav__list-toggle', function (toggleButton) {
            let $list = closest('.js-mega-nav__list', toggleButton);
            let $listItems = findAllIn('.js-mega-nav__list-item', $list);

            on('click', function () {
                let $parentItem = closest('.js-mega-nav__list-item', this);
                if (hasClass('is-open', $parentItem)) {
                    removeClass('is-open', $parentItem);
                } else {
                    if (isMobile()) {
                        if(hasClass('.js-mega-nav__list-toggle--quickfinder',toggleButton)){
                            dropdownPosition($parentItem);
                            $listItems.forEach(function (item) {
                                removeClass('is-open', item)
                            });
                            addClass('is-open', $parentItem);
                        }

                        if (closest('.mega-nav ', $parentItem)) {
                            closest('.mega-nav ', $parentItem).scrollTop = 0;
                        }
                        addClass('is-open', $parentItem);
                    } else {
                        dropdownPosition($parentItem);
                        $listItems.forEach(function (item) {
                            removeClass('is-open', item)
                        });
                        addClass('is-open', $parentItem);
                    }

                    window.addEventListener("click", function _listener(evt) {
                        if (!$parentItem.contains(evt.target) && !closest('.flatpickr-calendar', evt.target)) {
                            removeClass('is-open', $parentItem);

                            window.removeEventListener("click", _listener, true);
                        }
                    }, true);
                }
            }, toggleButton)
        });
    }
}

function dropdownPosition($parentItem) {
    let dropdown = findIn('.js-mega-nav__dropdown', $parentItem);
    if (dropdown.getBoundingClientRect().right > window.innerWidth) {
        addClass('main-navbar__dropdown--right', dropdown);
    }
}