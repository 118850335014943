import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-capacity-indicator__item', function (item) {
       let percentage = item.dataset.percentage;
       if(item.classList.contains('js-capacity-indicator__item--nav')){
           item.style.background = "conic-gradient(var(--color-secondary) 0%" + percentage + "%, var(--color-primary-light)" + percentage +"% )"
       }
       else {
           item.style.background = "conic-gradient(var(--color-secondary) 0%" + percentage + "%, var(--color-light)" + percentage +"% )"
       }
    });
}