"use strict";

import {find} from '@elements/dom-utils';

export function init () {
    let shariff = find('.js-shariff');

    if (shariff) {
        import('shariff').then(function () {
            new Shariff(shariff, {
                //do stuff
            });
        });
    }
}
